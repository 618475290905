const url = global.origin || window.location.origin || ''
let host
let type
let s3
let urlParams = {
  ebjdev: 'https://dev-cms.zzrcer.com/cms-admin',
  ebjqa: 'https://qa-cms.zzrcer.com/cms-admin',
  ebjsas: 'https://sas-cms.zzrcer.com/cms-admin',
  pro: 'https://www.chinauib.com.cn/prod-api',
}
let s3Params = {
  ebjdev: 'https://s3.chinauib.com/ubi-sc-dev',
  ebjqa: 'https://s3.chinauib.com/uib-sc-qa',
  ebjsas: 'https://s3.chinauib.com/uib-sc-sas',
  pro: 'https://s3.chinauib.com/uib-sc-prod',
}
if (url && url.indexOf('dev-cms') >= 0) {
  host = urlParams['ebjdev']
  s3 = s3Params['ebjdev']
  type = 'dev'
} else if (url && url.indexOf('qa-cms') >= 0) {
  host = urlParams['ebjqa']
  s3 = s3Params['ebjqa']
  type = 'qa'
} else if (url && url.indexOf('sas-cms') >= 0) {
  host = urlParams['ebjsas']
  s3 = s3Params['ebjsas']
  type = 'sas'
} else if (url && url.indexOf('chinauib.com') >= 0) {
  host = urlParams['pro']
  s3 = s3Params['pro']
  type = 'production'
} else {
  host = urlParams.ebjdev
  s3 = s3Params['ebjdev']
  type = 'dev'
}
// host = url && url.indexOf('https') < 0 ? host.replace('https', 'http') : host
console.log(url, host)
module.exports = {
  host: host,
  type: type,
  s3: s3,
}
